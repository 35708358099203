/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Riga"), React.createElement("p", null, "Pour se loger, Riga s’articule en quatre quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lv/riga/oldtown.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vecrīga"), " : la vieille ville se situe sur la rive nord de la Daugava, avec entre autres la cathédrale du Dôme."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lv/riga/citycentre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre de Riga"), ", au nord-est : la ville nouvelle commence au-delà du parc Bastejkalns."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lv/riga/latgalespriekspilseta.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Banlieue de Latgale"), ", à l’est : anciens quartiers ouvriers de Grīziņkalns et Avoti directement reliés au centre."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lv/riga/zemgalespriekspilseta.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Banlieue de Zemgale"), ", sur la rive sud : quartiers cossus et verdoyants d’Āgenskalns et Torņakalns."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
